import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import status from "./utils/status";
import config from "./config/index";
import 'video.js/dist/video-js.css';
import "./less/index.less";
import api from "./http/api";
import BuriedPoint from "./utils/buria-ploint";
import common from "./utils/common";
import {uploadCos} from "./utils/cos";
import {
	Row,
	Col,
	NavBar,
	Dialog,
	RadioGroup,
	Radio,
	Checkbox,
	CheckboxGroup,
	Button,
	Form,
	Field,
  Picker,
  Notify,
	Uploader,
	Loading,
	List,
	Empty,
	DatetimePicker,
	Popup,
	NoticeBar,
  CountDown,
	Tabs,
	Tab,
	Image as VanImage,
	Icon,
	Popover,
  Swipe,
  ActionSheet,
  SwipeItem
} from "vant";
import customComponent from "./components/index";

Vue.config.productionTip = false;
// 忽略微信呼起小程序标签
Vue.config.ignoredElements = ["wx-open-launch-weapp"];

Vue.prototype.$api = api;
Vue.prototype.$config = config;
Vue.prototype.$status = status;
Vue.prototype.$moment = moment;
Vue.prototype.$common = common;
Vue.prototype.$buriedPoint = BuriedPoint.init({
	app_channel: "wechat",
	app_name: "yzy",
});
Vue.prototype.$cos = uploadCos;
customComponent(Vue);
Vue.use(NavBar);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(RadioGroup);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Notify);
Vue.use(Form);
Vue.use(Uploader);
Vue.use(Field);
Vue.use(List);
Vue.use(Empty);
Vue.use(ActionSheet);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Row);
Vue.use(Col);
Vue.use(NoticeBar);
Vue.use(Loading);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Swipe)
Vue.use(CountDown)
Vue.use(SwipeItem);
Vue.use(Popover);

new Vue({
	router,
	store,
	render: (h) => h(App),
	mounted() {},
}).$mount("#app");
