import http from './index'

export default {
  base: {
    wxConfig: data => http.post(`/web/wechat/getJsconfig`, data),
    // 获取验证码
    sendCode: data => http.post(`/web/account/1/getMessageCode`, data),
    // 登录
    login: data => http.post(`/web/account/1/user/login`, data),
    // 获取医生信息
    getDoctorInfo: id => http.get(`/web/doctor/doctor/personalData?id=${id}`),
    // 获取COS 配置
    getCOSTempSign: data => http.post(`${process.env.VUE_APP_BASE_URL}/patient/oss/cpnt/getCosStsToken`, data)
  },
  meeting: {
    // 根据医生ID查询协议签署情况
    getDoctorAgreementStatus: data => http.get(`${process.env.VUE_APP_BASE_DM_URL}/sys/feign/meeting/getDoctorAgreementStatus`, data),
    // 获取会议劳务协议富文本
    getMeetingAgreementContent: data => http.get(`${process.env.VUE_APP_BASE_DM_URL}/represent/me/meeting/getSpeakerAgreementContent/${data.id}`, data),
    // 获取会议讲者信息
    getMeetingSpeakerInfo: data => http.get(`${process.env.VUE_APP_BASE_DM_URL}/sys/feign/meeting/getMeetingSpeakerInfo/${data.meetingId}/${data.doctorId}`, data),
    // 上传劳务协议
    uploadLaborAgreement: data => http.post(`${process.env.VUE_APP_BASE_DM_URL}/sys/feign/meeting/uploadLaborAgreement`, data),
  },
  work: {
    // 作品详情
    detail: data => http.get(`/web/works/${data.id}`, data),
    // 资讯内容详情
    inforDetail: data => http.get(`/web/home/information/${data.id}`),
    // i分身详情
    ifenshenInfoWork: id => http.get(`/web/queryVideoInfoByWorkId/${id}?browseNumber=1`),
    // i分身详情
    ifenshenInfo: id => http.get(`/web/queryVideoInfoById/${id}?browseNumber=1`),
    // 是否点赞
    getIsLike: id => http.get(`/web/works/${id}/isLike`),
    // 点赞
    likeArticle: (id) => http.get(`/web/works/${id}/giveALike`),
  },
  course: {
    // 会议详情
    detail: data => http.get(`${process.env.VUE_APP_BASE_URL_LIVE}/microWebsite/api/getContentInfo/${data.id}`),
    // 生成支付订单
    getPayOrder: data => http.post(`${process.env.VUE_APP_BASE_URL_LIVE}/microWebsite/pay/zettaPayOrder`,data),
    // 获取封装参数
    getCustomerServiceInfo: data => http.post(`/web/wxcode/getCustomerServiceInfo`,data)
  },
   // 活动
  activity: {
    // 判断活动进度状态
    checkActivityStatus: (data) => http.get('/web/taskStatus', data),
    // 检查任务可创作数量(0、可创作 1、暂存已占用次数达到上限、2、达到上限)
    checkTaskCount: (data) => http.get(`/web/works/checkTaskCount`, data),
  },
  surveys: {
    // i 调研列表
    page: data => http.post(`/web/surveys`, data),
    // i 调研列表的表单类型
    formTypePage: data => http.get(`/web/surveys/${data.surveyId}/forms`, data),
    // 表单详情
    formDetail: id => http.get(`/web/surveys/forms/${id}`),
    // 调研详情
    surveysDetail: id => http.get(`/web/surveys/${id}`),
    // 作答-提交
    submitAnswer: data => http.post(`/web/surveys/answers`, data),
    // 作答-分页
    asnwerPage: data => http.get(`/web/surveys/answers`, data),
    // 作答-详情
    asnwerDetail: id => http.get(`/web/surveys/answers/${id}`),
    // 作答-修改
    asnwerUpdate: data => http.put(`/web/surveys/answers/${data.id}`, data),
    // 检测是否包含ocr
    checkOcr: data => http.post(`/web/sys/ocr`, data),
    // 医生随访列表
    followPage: data => http.get(`/web/follow/answer`, data),
    // 医生随访详情
    followDetail: id => http.get(`/web/follow/answer/${id}`),
    // 随访提交
    followSubmitForm: data => http.put(`/web/follow/answer`, data),
    // 更新医生邮箱
    updateUserEmail: data => http.put(`/web/surveys/answers/ppt/email`, data),
  },
  redBracelet: {
    // 检测是否包含ocr
    getRankingList: data => http.post(`/web/ranking/list`, data),

  },
  user: {
    // 获取验证码
    getSmsCode: data => http.post(`web/account/1/getMessageCode`, data),
    // 登录
    login: data => http.post(`/web/account/1/user/login`, data),
    // 获取鉴权code
    getAuthCode: doctorId => http.get(`/blockchain/chain/authcode/${doctorId}`),
    // 获取用户信息
    getUserInfo: params => http.get(`/web/doctor/doctor/personalData`, params),
  }
}
